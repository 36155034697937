<template>
  <content>
    <div v-if="loading" class="text-center contmesa">
      <b-skeleton
        width="100"
        height="100"
        v-for="n in 50"
        :key="n"
        class="mesa"
        style="color: #dedede !important; background-color: #dedede !important"
      ></b-skeleton>
    </div>

    <div class="contmesa" v-if="view == 'mesas' && !layout && !loading">
      <mesas-geral-card
        v-for="mesa in ordened"
        :key="`mmesa+${Math.random(444)}` + mesa.cod_mesa"
        :class="{
          livre: mesa.dt_aberta == null,
          ocupada: mesa.dt_aberta != null,
          conta: mesa.dt_conta != null,
        }"
        :mesa="mesa"
        @click.native="openMesa(mesa)"
      />
    </div>
    <div
      class="d-flex py-1 px-2 text-center w-100  fixed-top"
      v-if="$store.state.isMobile && !loading"
    >
      <b-btn variant="primary" class=" " @click="$emit('openQrCode', 1)">
        <i class="fas fa-qrcode"></i>
        Abrir
      </b-btn>
      <b-btn variant="primary" class=" " @click="$emit('openQrCode', 2)">
        <i class="fas fa-qrcode"></i>
        Pedido
      </b-btn>

      <b-btn
        :variant="view == 'mesas' ? 'success' : 'secondary'"
        class=" "
        @click="view = 'mesas'"
      >
        <img width="25" src="@/assets/img/icones/table.png" />

        MESAS
      </b-btn>
      <b-btn
        :variant="view == 'comandas' ? 'success' : 'secondary'"
        class="   "
        @click="view = 'comandas'"
      >
        <i class="fa fa-barcode" />
        COMANDAS
      </b-btn>
    </div>
    <div class="contMesaMapa" v-if="view == 'mesas' && layout && !loading">
      <div
        class="text-left pl-3 d-flex justify-content-between"
        style="font-size: 4vw"
      >
       
        <span>
          <b-btn @click="findByComnanda" variant="info" :block="isMobile"
            ><i class="fa fa-barcode mr-1" /> Buscar Comanda</b-btn
          >
        </span>
      </div>
      <div class="salao scroll-horizontal">
        <div
          v-for="row in layout.layout"
          :style="`width:${Math.floor(Math.abs(100 / layout.layout.length))}%`"
          class="fila"
          :key="`row_${row.id}_${Math.random()}`"
        >
          <div
            class="mesa d-flex flex-column justify-content-center"
            @click="openMesa(it.obj)"
            :class="`${
              (VerificaMesaReunida(it) ? ' mesaJaUnida  ' : '') +
              ((it.id && it.juncao && it.juncao.length > 0) ||
              (!it.id && it.uniao)
                ? 'mesaUnida '
                : VerificaMesaReunida(it)
                ? ' mesaReunida '
                : '') +
              (it.obj && it.obj.dt_conta_solicitada != null
                ? ' mesaConta '
                : '') +
              (it.obj && it.obj.reservas && it.obj.reservas.length > 0
                ? ' mesaReservada'
                : '') +
              (it.obj && it.obj.dt_aberta == null ? ' mesaLivre ' : '') +
              (it.obj && it.obj.dt_aberta != null ? ' mesaOcupada ' : '')
            }`"
            v-for="it in row.items"
            :key="`mesa_${row.index}_${it.cod_mesa}`"
          >
            {{ it.title.replace("Mesa", "") }}
            <div
              class="reserva"
              v-if="it.obj && it.obj.reservas && it.obj.reservas.length > 0"
            >
              <b-icon-calendar-check
                :style="
                  it.obj.reservas.some((x) => x.dt_confirmado)
                    ? 'color: lightgreen'
                    : ''
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading && view == 'comandas'" class="p-0 p-sm-4 contMesaMapa">
      <mesa-new-comandas-openeds
        :isOpen="true"
        :mesas="mesas"
        @comanda="selectComanda"
        :show-open-comanda="true"
        @openComanda="openNewComanda"
      />
    </div>

    <b-modal
      id="modal-mesa"
      :title="`${mesaSelected ? mesaSelected.mesa : ''}`"
      size="xl"
      hide-footer
      :modal-class="
        $store.state.isMobile ? `modal-full p-0 ` : 'modal-full p-0'
      "
      @hide="closeMesa()"
      :body-class="`p-0`"
      :content-class="`p-0 ${$store.state.isMobile ? `fixed` : 'fixed'}`"
      :hide-header-close="$store.state.isMobile"
      :hide-header="true"
    >
      <mesa-new
        :mesa="mesaSelected"
        :data="data"
        :empresa="empresa"
        @closeMesa="closeMesa"
        @updateMesa="(mesa) => $emit('updateMesa', mesa)"
        @mesaFechada="closeMesa"
        @carregar="carregar"
        :mesas="mesas"
        @changeMesa="openMesa"
        :mesaLayout="layout"
        :comandaSelected="comandaSelected"
        :openingComanda="openingComanda"
        :typeOpen="typeOpen"
      />

      <!-- v-if="!$store.state.isMobile" -->

      <!-- <mesa-detail
        v-if="false"
        :mesa="mesaSelected"
        :data="data"
        :empresa="empresa"
        @closeMesa="closeMesa"
        @updateMesa="(mesa) => $emit('updateMesa', mesa)"
        @mesaFechada="closeMesa"
      /> -->
    </b-modal>
    <b-modal id="modal-find-comanda" hide-footer>
      <mesa-new-comandas-openeds :mesas="mesas" @comanda="selectComanda" />
    </b-modal>
  </content>
</template>

<script>
// import MesaDetail from "./MesaDetail.vue";
import MesasGeralCard from "./MesasGeralCard.vue";

import MesaNewComandasOpeneds from "./MesaNewComandasOpeneds.vue";
export default {
  components: {
    MesasGeralCard,
    // MesaDetail,
    MesaNew: () => import("./MesaNew.vue"),
    MesaNewComandasOpeneds,
  },
  props: {
    mesas: Array,
    empresa: Object,
    data: String,
    loading: Boolean,
    view: String,
  },
  mounted() {
    // console.log('emp1',this.empresa);
    this.$emit("updateMesas");
    // console.log("DATA", this.data);
    this.verificaLayout();
    if (location.href.includes("#")) {
      console.log("location", location.href);
      let m = this.mesas.find(
        (x) =>
          x.cod_mesa ==
          location.href.split("#openMesa")[1].split("=")[1].split("#reserva")[0]
      );

      if (m) {
        this.openMesa(m);
      }
      // location.href = location.href.split("#openMesa")[0];
    }
  },

  data() {
    return {
      mesaSelected: null,
      mesaLayout: null,
      layout: null,
      comandaSelected: null,
      // loading: true,
      loadedLayout: false,
      openingComanda: "",
      typeOpen: null,
    };
  },
  watch: {
    empresa: {
      deep: true,
      handler() {},
    },
    mesas: {
      deep: true,
      handler() {
        // console.log(this.mesas);
        this.loading = false;
        this.fillMesaObjs();
      },
    },
  },
  computed: {
    ordened() {
      return [...this.mesas].sort((a, b) => (a.id && !b.id ? -1 : 1));
    },
  },
  methods: {
    openNewComanda(initialValue) {
      console.log("mesas", this.mesas, initialValue);
      const firstItem = this.mesas.reverse().find((item) => !item.id);
      console.log("firstItem", firstItem);
      this.openMesa(firstItem, initialValue);
    },
    selectComanda(comanda, typeOpen = null) {
      console.log("selectComanda", comanda);
      this.typeOpen = typeOpen;
      this.comandaSelected = comanda;
      let mesa = null;
      if (comanda.mesa_controle_id) {
        mesa = this.mesas.find(
          (x) => x && x.id && x.id == comanda.mesa_controle_id
        );
      }
      this.mesaSelected = mesa;
      // this.comandaSelected = null;

      this.$bvModal.hide("modal-find-comanda");
      this.$bvModal.show("modal-mesa");
    },
    findByComnanda() {
      this.$bvModal.show("modal-find-comanda");
    },
    getMesa(cod_mesa) {
      return this.mesas.find((x) => x.cod_mesa == cod_mesa);
    },
    verificaLayout() {
      if (!this.loadedLayout) {
        if (
          this.empresa &&
          this.empresa.configs &&
          this.empresa.configs.mesasLayout &&
          this.empresa.configs.mesasLayout.length > 0
        ) {
          let has = this.empresa.configs.mesasLayout.find(
            (x) => x.ativo === true
          );
          if (has) {
            this.layout = has;
            this.fillMesaObjs();
          }
        }
        this.loadedLayout = true;
      }
    },
    fillMesaObjs() {
      if (!this.loaded) {
        if (this.layout && this.layout.layout) {
          for (let r of this.layout.layout) {
            for (let it of r.items) {
              it.obj = this.getMesa(it.cod_mesa);
              //console.log("it",it.obj)
            }
          }
          //console.log("layout", this.layout.layout);
        }
        //  this.loaded = true;
      }
      /*
      if (this.layout && this.layout.layout && this.layout.layout.length > 0) {
        // console.log("has layout");
        for (let mesa of this.mesas.filter(
          (x) => x.juncao && x.juncao.length > 0
        )) {
          // console.log("has mesa com juncao", mesa);
          //let juntar = [mesa.cod_mesa];
          //for (let i = 0; i < mesa.juncao.length; i++) {
           // juntar.push(mesa.juncao[i].cod_mesa);
            // let mesacd = mesa.juncao[i].cod_mesa;
            // let fnd = this.layout.layout.find((x) =>
            //   x.items.find((z) => z.cod_mesa == mesacd)
            // );

            // if (fnd) {
            //   fnd = fnd.items.find((x) => x.cod_mesa == mesacd);
            //   if (i == 0) {
            //     fnd.uniao = {
            //       cod_mesa: mesa.cod_mesa,
            //     };
            //     console.log(
            //       "juntando mesa " +
            //         fnd.cod_mesa +
            //         " com a mesa " +
            //         mesa.cod_mesa
            //     );
            //   } else {
            //     fnd.uniao = { cod_mesa: mesa.juncao[i - 1].cod_mesa };
            //     console.log(
            //       "juntando mesa " +
            //         fnd.cod_mesa +
            //         " com a mesa " +
            //         mesa.juncao[i - 1].cod_mesa
            //     );
            //   }
            // }
       //   }
          // juntar = juntar.sort((a, b) => (a < b ? -1 : 1));
          // for (let mesa of this.mesas.filter((x) =>
          //   juntar.includes(x.cod_mesa)
          // )) {
          //   mesa.uniao = juntar;
          // }
          // // console.log("junstar", juntar);
          // for (let i = 0; i <= juntar.length; i++) {
          //   let cod_mesa = juntar[i];
          //   let fndRow = this.layout.layout.find((r) =>
          //     r.items.some((it) => it.cod_mesa == cod_mesa)
          //   );
          //   if (fndRow) {
          //     let fnd = fndRow.items.find((x) => x.cod_mesa == cod_mesa);
          //     if (fnd && i + 1 < juntar.length) {
          //       fnd.uniao = { cod_mesa: juntar[i + 1] };
          //       // console.log("juntou", fnd.cod_mesa, juntar[i - 1]);
          //     }
          //   }
          // }
          // console.log("ficou", this.layout.layout);
        }
      }*/
      if (this.mesaSelected && this.mesaSelected.cod_mesa) {
        let has = this.mesas.find(
          (x) => x && x.cod_mesa && x.cod_mesa === this.mesaSelected.cod_mesa
        );
        // console.log("findMesa", has);
        if (this.mesaSelected.cod_mesa !== has.cod_mesa) {
          this.mesaSelected = has;
        }
        // this.mesaLaout =

        // if(!has || !has.id){
        //   this.$swal("Atenção","A mesa foi fechada!", "information");
        //   this.closeMesa();
        // }
      }
    },
    openMesa(mesa, comanda = null) {
      console.log("openMesa", mesa);
      if (mesa && mesa.mesa_controle_id) {
        mesa = this.mesas.find(
          (x) => x && x.id && x.id == mesa.mesa_controle_id
        );
      }
      this.mesaSelected = mesa;
      console.log("openMesa:mesaSelected", mesa);
      this.comandaSelected = null;
      this.openingComanda = comanda;
      this.$forceUpdate();
      this.$bvModal.show("modal-mesa");
    },
    closeMesa() {
      this.comandaSelected = null;
      this.mesaSelected = null;
      this.$bvModal.hide("modal-mesa");
      this.$emit("updateMesas");
    },
    carregar() {
      this.$emit("updateMesas");
    },
    VerificaMesaReunida(item) {
      if (
        !this.layout ||
        !this.layout.layout ||
        this.layout.layout.length == 0
      ) {
        return false;
      }
      let has =
        this.layout.layout.filter((r) =>
          r.items.some((x) => x.uniao && x.uniao.cod_mesa == item.cod_mesa)
        ).length > 0;
      if (item.id) {
        has = item.juncao && item.juncao.length > 0;
      }

      return has;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mesas.scss";
.contMesaMapa {
  @media screen and (max-width: 575px) {
    top: 90px;
    position: absolute;
    height: calc(100vh - 80px);
    overflow: hidden;
  }
}
.salao {
  user-select: none !important;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 200px;
  padding: 3px;
  height: 100%;
 
  .fila {
    padding: 3px;
    height: auto;
    margin: 2px;
    font-size: 1vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-left: 1px;
    padding-right: 1px;
    min-width: 80px;

    align-items: center;
    .mesa {
      font-size: 2.8vw;
      font-weight: bold;
      position: relative;
      background-color: #dedede;
      width: 100%;
      min-height: 47px;
      text-align: center;
      margin-top: 5px;
      border-radius: 15px;
      color: #777;
      cursor: pointer;
      &:hover,
      &:active {
        background-color: #ccc;
      }
      @media only screen and (min-width: 575px) {
        font-size: 2vw;
        min-height: 80px;
      }
    }

    .mesaOcupada {
      background-color: green !important;
      color: white;
      &:hover {
        background-color: darkgreen;
      }
    }
    .mesaLivre {
      background-color: #dedede;
    }
    .mesaConta {
      background-color: rgb(181, 11, 11);
      color: white;
    }
    .mesaUnida {
      border-end-end-radius: 0px;
      border-end-start-radius: 0px;
      border-bottom-width: 0px;
    }
    .mesaJaUnida {
      margin-top: 0px;
      border-top-width: 0px;
      border-start-end-radius: 0px;
      border-start-start-radius: 0px;
    }
    .mesaReunida {
      margin-top: 0px;
      border-top-width: 0px;
      border-start-end-radius: 0px;
      border-start-start-radius: 0px;
    }
    .mesaReservada {
      background-color: #c7bdb0;
    }
    .reserva {
      position: absolute;
      font-size: 12px;
      top: -5px;

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(172, 115, 0);
      color: white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  &.scroll-horizontal {
    overflow-x: auto;
    width: 100%;
  }
}

.fixed-top {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;

  .btn {
    width: 90px;
    height: 50px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 0;
    transition: all 0.2s ease;
    
    i, img {
      font-size: 18px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      font-size: 10px;
      line-height: 1;
    }

    &:active {
      transform: scale(0.96);
      opacity: 0.9;
    }

    &.btn-primary {
      background-color: #007AFF;
      border: none;
      color: white;
      
      &:hover, &:focus {
        background-color: #0062CC;
      }
    }

    &.btn-success {
      background-color: #34C759;
      border: none;
      color: white;
      
      &:hover, &:focus {
        background-color: #2DAE4E;
      }
    }

    &.btn-secondary {
      background-color: #F2F2F7;
      border: none;
      color: #000000;
      
      &:hover, &:focus {
        background-color: #E5E5EA;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .fixed-top {
    display: none;
  }
}
</style>
